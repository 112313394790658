import React from "react"

import Layout from "../components/layout"
import InnerLayout from "../components/inner-layout"

import SEO from "../components/seo"

import { Container, Row, Col } from "react-bootstrap"

const ContactUs = () => (
  <Layout>
    <SEO title="Contact Us" />
    <InnerLayout>
      <Container>
        <Col className="text-center mx-auto mb-5" md={8}>
          <h1 className="text-center">Contact Us</h1>
          <p>
            It would be great to hear from you. If you are interested in a
            particular piece or would like to learn more about our products feel
            free to either fill the below form in, drop in to see us or give us
            a call on 01765 689 780.
          </p>
        </Col>
        <Row>
          <Col md={6}>
            <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
              <input type="hidden" name="form-name" value="contact"/>

              <div className="form-group">
                <label>Name:
                  <input className="form-control" type="text" name="name" />
                </label>
              </div>

              <div className="form-group">
                <label>Email:
                  <input className="form-control" type="email" name="email" />
                </label>
              </div>

              <div className="form-group">
                <label>Message:
                  <textarea className="form-control" name="message" rows="5"></textarea>
                </label>
              </div>

              <button className="btn btn-primary" type="submit">Send Message</button>

              <div class="invisible">
                <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
              </div>

            </form>
          </Col>
          <Col md={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d298576.7408342738!2d-1.9364744687671436!3d54.22202398017188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487ea840a7ffc345%3A0xaa39d35d2039d15e!2sUredale%20Glass!5e0!3m2!1sen!2suk!4v1607025634886!5m2!1sen!2suk"
              width="100%"
              height="400"
              frameborder="0"
              className="border-0"
              allowfullscreen=""
              aria-hidden="false"
              title="Find Uredale Glass"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </InnerLayout>
  </Layout>
)

export default ContactUs
